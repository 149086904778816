import React from "react";
import { graphql } from "gatsby";
import ContentTiles from "./ContentTiles";
import {
  StringValue,
  KontentMultiChoice,
  KontentContentTile,
} from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";
import { formatContentTiles } from "../../../lib/contentTiles";

export type tileLayouts =
  | "full_width"
  | "onethird_twothird"
  | "twothird_onethird"
  | "thirds";

export interface ContentTilesProps {
  tile_layout: KontentMultiChoice<tileLayouts>;
  content_tiles: {
    modular_content: KontentContentTile[];
  };
  component_settings__anchor_name: StringValue;
  component_settings__hide_on_mobile: KontentMultiChoice<"yes" | "no">;
  component_layout__component_width: KontentMultiChoice<"full" | "contained">;
  id: string;
}

const ContentTilesContainer: React.FC<ContentTilesProps> = ({
  tile_layout,
  content_tiles,
  component_layout__component_width,
  component_settings__anchor_name,
  component_settings__hide_on_mobile,
  id,
}) => {
  const tileLayout = getMultiChoice(tile_layout);

  if (!content_tiles?.modular_content) return null;

  const contentTiles = formatContentTiles(content_tiles.modular_content);
  const componentWidth = getMultiChoice(component_layout__component_width);

  if (!contentTiles) return null;

  return (
    <ContentTiles
      tileLayout={tileLayout}
      contentTiles={contentTiles}
      width={componentWidth}
      anchor={component_settings__anchor_name.value}
      hideOnMobile={
        getMultiChoice(component_settings__hide_on_mobile) === "yes"
      }
      id={id}
    />
  );
};

export default ContentTilesContainer;

// Verify kontent_item_component___ name

export const fragmentContentTiles = graphql`
  fragment ContentTiles on kontent_item_component___content_tile {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      component_layout__component_width {
        value {
          codename
        }
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      tile_layout {
        value {
          codename
        }
      }
      content_tiles {
        modular_content {
          ...kontentContentTile
        }
      }
    }
  }
`;
