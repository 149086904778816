import React from "react";
import * as styles from "./OneThirdContentTiles.module.css";
import { ComponentProps, ComponentWidth } from "../../../types";
import {
  ContentTile,
  ContentTileProps,
} from "../../elements/ContentTile/ContentTile";
import { SectionWrapper } from "../../containers/SectionWrapper";

interface Props extends ComponentProps, ComponentWidth {
  contentTiles: ContentTileProps[];
}

export const OneThirdContentTiles: React.FC<Props> = ({
  contentTiles,
  width,
  ...props
}) => {
  const sectionWidthStyles =
    width === "contained"
      ? `${styles.onethirdcontenttiles} ${styles.contained}`
      : styles.onethirdcontenttiles;

  if (!contentTiles) return null;

  const renderContentTiles = () => {
    return contentTiles.map((tile, idx) => {
      return (
        <ContentTile
          key={`contentTile-${idx}`}
          {...tile}
          tileWidth="oneThird"
        />
      );
    });
  };

  return (
    <SectionWrapper {...props}>
      <div
        className={sectionWidthStyles}
        data-kontent-element-codename="content_tiles"
      >
        {renderContentTiles()}
      </div>
    </SectionWrapper>
  );
};
