import React from "react";
import { FullWidthContentTiles } from "./FullWidthContentTiles";
import { tileLayouts } from "./index";
import { OneThirdTwoThirdContentTiles } from "./OneThirdTwoThirdContentTiles";
import { OneThirdContentTiles } from "./OneThirdContentTiles";
import { ComponentProps, ComponentWidth } from "../../../types";
import { ContentTileProps } from "../../elements/ContentTile/ContentTile";

interface Props extends ComponentProps, ComponentWidth {
  tileLayout: tileLayouts;
  contentTiles: ContentTileProps[];
  id: string;
}

const ContentTiles: React.FC<Props> = ({
  tileLayout,
  contentTiles,
  ...props
}) => {
  if (!contentTiles) return null;

  switch (tileLayout) {
    case "full_width":
      return <FullWidthContentTiles contentTiles={contentTiles} {...props} />;
    case "thirds":
      return <OneThirdContentTiles contentTiles={contentTiles} {...props} />;
    default:
      return (
        <OneThirdTwoThirdContentTiles
          contentTiles={contentTiles}
          tileLayout={tileLayout}
          {...props}
        />
      );
  }
};

export default ContentTiles;
