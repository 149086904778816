import React from "react";
import * as styles from "./OneThirdTwoThirdContentTiles.module.css";
import { ComponentProps, ComponentWidth } from "../../../types";
import {
  ContentTile,
  ContentTileProps,
} from "../../elements/ContentTile/ContentTile";
import { SectionWrapper } from "../../containers/SectionWrapper";

interface Props extends ComponentProps, ComponentWidth {
  contentTiles: ContentTileProps[];
  tileLayout: string;
}

export const OneThirdTwoThirdContentTiles: React.FC<Props> = ({
  contentTiles,
  width,
  tileLayout,
  ...props
}) => {
  const sectionWidthStyles =
    width === "contained"
      ? `${styles.onethirdtwothirdcontenttiles} ${styles.contained}`
      : styles.onethirdtwothirdcontenttiles;

  if (!contentTiles) return null;

  return (
    <SectionWrapper {...props}>
      <div
        className={sectionWidthStyles}
        data-kontent-element-codename="content_tiles"
      >
        <ContentTile
          {...contentTiles[0]}
          tileWidth={
            tileLayout === "onethird_twothird" ? "oneThird" : "twoThirds"
          }
        />
        {contentTiles[1] && (
          <ContentTile
            {...contentTiles[1]}
            tileWidth={
              tileLayout === "onethird_twothird" ? "twoThirds" : "oneThird"
            }
          />
        )}
      </div>
    </SectionWrapper>
  );
};
