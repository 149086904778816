import React from "react";
import * as styles from "./FullWidthContentTiles.module.css";
import { ComponentProps, ComponentWidth } from "../../../types";
import {
  ContentTile,
  ContentTileProps,
} from "../../elements/ContentTile/ContentTile";
import { SectionWrapper } from "../../containers/SectionWrapper";

interface Props extends ComponentProps, ComponentWidth {
  contentTiles: ContentTileProps[];
}

export const FullWidthContentTiles: React.FC<Props> = ({
  contentTiles,
  width,
  ...props
}) => {
  const sectionWidthStyles =
    width === "contained"
      ? `${styles.fullwidthcontenttiles} ${styles.contained}`
      : styles.fullwidthcontenttiles;

  if (!contentTiles) return null;

  return (
    <SectionWrapper {...props}>
      <div className={sectionWidthStyles}>
        <ContentTile
          {...contentTiles[0]}
          tileWidth="full"
          data-kontent-element-codename="content_tiles"
        />
      </div>
    </SectionWrapper>
  );
};
